import {Config} from '../config/IISMethods'

const initialState = {
    data : [],
    fieldOrder: [],
    newFieldOrder: [],
    pagelimit: 20,
    pageNo: 1,
    nextpage : 1,
    // ApplicationNextPage : 1,
    // applicationmodalPageNo : 1,
    pageName : '',
    sortData : {},
    formData : {},
    childFormData : {},
    masterFormData : {},
    filterData: {},
    oldFilterData: {},
    rightSidebarFormData : [],
    masterRightSidebarFormData : [],
    fieldobjectformasterdata : {},
    rightSidebarShimmer : false, 
    masterData : {},
    masterDataList: {},
    modal : {},

    // timerdata : [],
    // timerpageNo : 1,
    
    searchValue : '',
    searchResultArray : [] ,
    // documentData : [] ,
    menuData : [],
    // seriesOrder : [],
    // applicationData : [],
    // secondarySourceMasterData : '',
    // candidateRightSidebarFormData : [],
    // candidateFormData : {},
    // currentOpening : '',
    // currentCandidate: {},
    // currentApplication  : {},
    // listOfCandidates : [],
    // followupFormData : {},
    // netxStageFromData : {},
    orgChartData : {},
    // editedJoiningStage : {},
    // stages : [],
    // currentStageIndex : 0,
    // editedQuestionData : {},
    loginresp : {},
    // ApplicationBasicInfo : [],
    // FollowupModalData : [],
    // momFormData  : {},

    // API Manager
    // APIRequestList: [],

    //ENV List
    envdata: [],

    // Notification:
    notifications: [],
    notificationLimit: 10,
    notificationPageNo: 1,
    notificationNextpage : 1,
    notificationCount : 0,

    //Recruitment Notification
    // recruitmentNotification : [],
    // recruitmentNotificationLimit: 10,
    // recruitmentNotificationPageNo: 1,
    // recruitmentNotificationNextpage : 1,
    // recruitmentNotificationCount : 0,

    //Global Search
    searchItems : [],

    //from notification to opening
    redirectPagename : "",
    redirectItem : {},
    redirectFromNotification : 0,

    // reapproach
    // reapproach : 0,

    //global search
    searchCandidate : [],
    // announcements :[],

    // disableButton : false

    // cookieData
    cookiesData: {},
    // localStorageData
    localStorageData: {},
    // hemang 9/1/22
    renderSubheaderRouter : false,
    // harsh 17-1-2023
    // headerAnnouncement : [],
    // harsh 26-4-2023
    headerModal: {},

    // for user Type
    userType :{},

    // Project
    // globalTimer : {}, // juhil - 5-5-2023,


    userRights : [],
    // supportRating : {},
    // assetAssign : {},

    deleteModalData : {},
    // do not change
    //*************************************** Secret Keys Start ****************************************/ 
    REACT_APP_SECRETKEY : 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3', //for get access tocken
    REACT_APP_KEY : 'Bizcompass_98F2B6E0-240A-457B-853A-A0079862315F', //for encryption
    REACT_APP_ISSUER : 'website',
    REACT_APP_PLATFORM : 1,
    REACT_APP_METHOD_GET :'GET',
    REACT_APP_METHOD_POST : 'POST',
    REACT_APP_METHOD_DEL : 'DELETE',
    REACT_APP_REQ_LIST : '',
    REACT_APP_REQ_ADD : 'add',
    REACT_APP_REQ_UPDATE : 'update',
    REACT_APP_REQ_DELETE : 'delete',
    //*************************************** Secret Keys End ****************************************/ 

    envConfig: {}, // For env credencial

    //************************************* AWS Cognito Credentials Start *************************************
    // for live
    // POOL_NAME : 'bizcompass_react',
    // POOL_PASS : 'Asd@123!@#',
    // USER_POOL_ID : "us-east-1_9wd50z9ge",
    // POOL_CLIENT_ID : '2hptp1b8v23u956u07t7m8i167' ,
    // IDENTITY_POOL_ID : 'us-east-1:137d1c33-8a04-41ac-b128-12625c37f357',
    // S3_BUCKET_NAME : 'bizcompass'
    
    // for uat and local
    POOL_NAME : Config.POOL_NAME ,
    POOL_PASS : Config.POOL_PASS ,
    USER_POOL_ID : Config.USER_POOL_ID,
    POOL_CLIENT_ID : Config.POOL_CLIENT_ID,
    IDENTITY_POOL_ID : Config.IDENTITY_POOL_ID,
    S3_BUCKET_NAME : Config.S3_BUCKET_NAME

    //************************************* AWS Cognito Credentials End *************************************

}

const appReducer = (state = initialState, action) => {
    switch (action.type){
        case 'setProps' :
            return {
                ...state,
                pageName : (action.payload.pageName !== undefined ? action.payload.pageName : state.pageName),
                pageNo : (action.payload.pageNo !== undefined ? action.payload.pageNo : state.pageNo),
                // applicationmodalPageNo : (action.payload.applicationmodalPageNo !== undefined ? action.payload.applicationmodalPageNo : state.applicationmodalPageNo),
                pagelimit : (action.payload.pagelimit !== undefined ? action.payload.pagelimit : state.pagelimit),
                data : (action.payload.data !== undefined ? state.data.concat(action.payload.data) : state.data),
                nextpage : (action.payload.nextpage !== undefined ? action.payload.nextpage : state.nextpage),
                // ApplicationNextPage : (action.payload.ApplicationNextPage !== undefined ? action.payload.ApplicationNextPage : state.ApplicationNextPage),
                sortData : (action.payload.sortData !== undefined ? action.payload.sortData : state.sortData),
                fieldOrder : (action.payload.fieldOrder !== undefined ? action.payload.fieldOrder : state.fieldOrder),
                newFieldOrder : (action.payload.newFieldOrder !== undefined ? action.payload.newFieldOrder : state.newFieldOrder),
                formData : (action.payload.formData !== undefined ? action.payload.formData : state.formData),
                childFormData : (action.payload.childFormData !== undefined ? action.payload.childFormData : state.childFormData),
                loginresp : (action.payload.loginresp !== undefined ? action.payload.loginresp : state.loginresp),
                masterFormData : (action.payload.masterFormData !== undefined ? action.payload.masterFormData : state.masterFormData),
                filterData : (action.payload.filterData !== undefined ? action.payload.filterData : state.filterData),
                oldFilterData : (action.payload.oldFilterData !== undefined ? action.payload.oldFilterData : state.oldFilterData),
                rightSidebarFormData : (action.payload.rightSidebarFormData !== undefined ? action.payload.rightSidebarFormData : state.rightSidebarFormData),
                masterRightSidebarFormData : (action.payload.masterRightSidebarFormData !== undefined ? action.payload.masterRightSidebarFormData : state.masterRightSidebarFormData),
                fieldobjectformasterdata : (action.payload.fieldobjectformasterdata !== undefined ? action.payload.fieldobjectformasterdata : state.fieldobjectformasterdata),
                rightSidebarShimmer : (action.payload.rightSidebarShimmer !== undefined ? action.payload.rightSidebarShimmer : state.rightSidebarShimmer),
                masterData : (action.payload.masterData !== undefined) ? action.payload.masterData : state.masterData,
                masterDataList : (action.payload.masterDataList !== undefined) ? action.payload.masterDataList : state.masterDataList,
                modal : (action.payload.modal !== undefined) ? action.payload.modal : state.modal,

                // timerpageNo : (action.payload.timerpageNo !== undefined ? action.payload.timerpageNo : state.timerpageNo),
                // timerdata : (action.payload.timerdata !== undefined ? action.payload.timerdata : state.timerdata),

                // applicationData : (action.payload.applicationData !== undefined ? state.applicationData.concat(action.payload.applicationData) : state.applicationData),
                menuData : (action.payload.menuData !== undefined ? action.payload.menuData : state.menuData),
                searchValue : (action.payload.searchValue !== undefined) ? action.payload.searchValue : state.searchValue,
                searchResultArray : (action.payload.searchResultArray !== undefined) ? action.payload.searchResultArray : state.searchResultArray,
                // documentData : (action.payload.documentData !== undefined) ? action.payload.documentData : state.documentData,
                // seriesOrder : (action.payload.seriesOrder !== undefined ? action.payload.seriesOrder : state.seriesOrder),

                // secondarySourceMasterData : (action.payload.secondarySourceMasterData !== undefined ? action.payload.secondarySourceMasterData : state.secondarySourceMasterData),
                // candidateRightSidebarFormData : (action.payload.candidateRightSidebarFormData !== undefined ? action.payload.candidateRightSidebarFormData : state.candidateRightSidebarFormData),
                // ApplicationBasicInfo : (action.payload.ApplicationBasicInfo !== undefined ? action.payload.ApplicationBasicInfo : state.ApplicationBasicInfo),
                // FollowupModalData : (action.payload.FollowupModalData !== undefined ? action.payload.FollowupModalData : state.FollowupModalData),
                // candidateFormData : (action.payload.candidateFormData !== undefined ? action.payload.candidateFormData : state.candidateFormData),
                // currentOpening : (action.payload.currentOpening !== undefined ? action.payload.currentOpening : state.currentOpening),
                // listOfCandidates : (action.payload.listOfCandidates !== undefined ? state.listOfCandidates.concat(action.payload.listOfCandidates) : state.listOfCandidates),
                // currentCandidate : (action.payload.currentCandidate !== undefined ? action.payload.currentCandidate : state.currentCandidate),
            
                // followupFormData : (action.payload.followupFormData !== undefined ? action.payload.followupFormData : state.followupFormData),
                // netxStageFromData : (action.payload.netxStageFromData !== undefined ? action.payload.netxStageFromData : state.netxStageFromData),
                // currentApplication : (action.payload.currentApplication !== undefined ? action.payload.currentApplication : state.currentApplication),
                orgChartData : (action.payload.orgChartData !== undefined ? action.payload.orgChartData : state.orgChartData),
                // stages : (action.payload.stages !== undefined ? action.payload.stages : state.stages),
                // editedJoiningStage : (action.payload.editedJoiningStage !== undefined ? action.payload.editedJoiningStage : state.editedJoiningStage),
                // editedQuestionData : (action.payload.editedQuestionData !== undefined ? action.payload.editedQuestionData : state.editedQuestionData),
                // currentStageIndex : (action.payload.currentStageIndex !== undefined ? action.payload.currentStageIndex : state.currentStageIndex),
                // momFormData : (action.payload.momFormData !== undefined ? action.payload.momFormData : state.momFormData),
                // APIRequestList: (action.payload.APIRequestList !== undefined ? action.payload.APIRequestList : state.APIRequestList),
                envdata: (action.payload.envdata !== undefined ? action.payload.envdata : state.envdata),
                presetData: (action.payload.presetData !== undefined ? action.payload.presetData : state.presetData),

                notifications: (action.payload.notifications !== undefined ? action.payload.notifications : state.notifications),
                notificationLimit: (action.payload.notificationLimit !== undefined ? action.payload.notificationLimit : state.notificationLimit),
                notificationPageNo: (action.payload.notificationPageNo !== undefined ? action.payload.notificationPageNo : state.notificationPageNo),
                notificationNextpage: (action.payload.notificationNextpage !== undefined ? action.payload.notificationNextpage : state.notificationNextpage),
                // recruitmentNotification: (action.payload.recruitmentNotification !== undefined ? action.payload.recruitmentNotification : state.recruitmentNotification),
                // recruitmentNotificationLimit: (action.payload.recruitmentNotificationLimit !== undefined ? action.payload.recruitmentNotificationLimit : state.recruitmentNotificationLimit),
                // recruitmentNotificationPageNo: (action.payload.recruitmentNotificationPageNo !== undefined ? action.payload.recruitmentNotificationPageNo : state.recruitmentNotificationPageNo),
                // recruitmentNotificationNextpage: (action.payload.recruitmentNotificationNextpage !== undefined ? action.payload.recruitmentNotificationNextpage : state.recruitmentNotificationNextpage),
                searchItems: (action.payload.searchItems !== undefined ? action.payload.searchItems : state.searchItems),
                notificationCount: (action.payload.notificationCount !== undefined ? action.payload.notificationCount : state.notificationCount),
                // recruitmentNotificationCount: (action.payload.recruitmentNotificationCount !== undefined ? action.payload.recruitmentNotificationCount : state.recruitmentNotificationCount),
                
                redirectPagename: (action.payload.redirectPagename !== undefined ? action.payload.redirectPagename : state.redirectPagename),
                redirectItem: (action.payload.redirectItem !== undefined ? action.payload.redirectItem : state.redirectItem),
                redirectFromNotification: (action.payload.redirectFromNotification !== undefined ? action.payload.redirectFromNotification : state.redirectFromNotification),

                // reapproach: (action.payload.reapproach !== undefined ? action.payload.reapproach : state.reapproach),
                searchCandidate: (action.payload.searchCandidate !== undefined ? action.payload.searchCandidate : state.searchCandidate),
                // announcements: (action.payload.announcements !== undefined ? action.payload.announcements : state.announcements),
                renderSubheaderRouter: (action.payload.renderSubheaderRouter !== undefined ? action.payload.renderSubheaderRouter : state.renderSubheaderRouter),
                // disableButton: (action.payload.disableButton !== undefined ? action.payload.disableButton : state.disableButton),
                // headerAnnouncement: (action.payload.headerAnnouncement !== undefined ? action.payload.headerAnnouncement : state.headerAnnouncement),
                headerModal: (action.payload.headerModal !== undefined ? action.payload.headerModal : state.headerModal),
                userType: (action.payload.userType !== undefined ? action.payload.userType : state.userType),

                deleteModalData : (action.payload.deleteModalData !== undefined ? action.payload.deleteModalData : state.deleteModalData),
                // globalTimer: (action.payload.globalTimer !== undefined ? action.payload.globalTimer : state.globalTimer),
                // supportRating: (action.payload.supportRating !== undefined ? action.payload.supportRating : state.supportRating),
                // assetAssign: (action.payload.assetAssign !== undefined ? action.payload.assetAssign : state.assetAssign)
                envConfig: (action.payload.envConfig !== undefined ? action.payload.envConfig : state.envConfig),
          
            }
            
        // case 'clearTimerData' :
        //     return{
        //         ...state,
        //         timerdata : []
        //     }

        case 'clearData' :
            return {
                ...state,
                data : []
            }
        // case 'clearApplicationData' : 
        //     return{
        //         ...state,
        //         applicationData : [],
        //         listOfCandidates : []
        //     }

        // case 'setApplicationData' : 
        //     return {
        //         ...state,
        //         applicationData : action.payload.applicationData
        //     }

        // case 'setListOfCandidates' : 
        //     return {
        //         ...state,
        //         listOfCandidates : action.payload.listOfCandidates
        //     }
        case 'clearmasterData' : 
            return{
                ...state,
                masterData : []
            }  
        case 'clearsearchResultArray' :
            return {
                ...state,
                searchResultArray : []
            } 
        case 'updateData'  : 
            return {
                ...state,
                data : (action.payload.data !== undefined) ? action.payload.data : state.data,
                envdata: (action.payload.envdata !== undefined) ? action.payload.envdata : state.envdata
                // data : (action.payload.data !== undefined ? state.data.concat(action.payload.data) : state.data)
            }
        case 'updatesearchResultArray' :
            return {
                ...state,
                searchResultArray : (action.payload.searchResultArray !== undefined) ? action.payload.searchResultArray : state.searchResultArray
            }

        case 'setCookiesData' : 
            return {
                ...state,
                cookiesData : (action.payload.cookiesData !== undefined) ? action.payload.cookiesData : state.cookiesData
            }

        case 'setLocalStorageData' : 
            return {
                ...state,
                localStorageData : (action.payload.localStorageData !== undefined) ? action.payload.localStorageData : state.localStorageData
            }
        
        // used in announcement controller
        case 'setGridListData' : 
            return{
                ...state,
                data : action.payload.data
            }
        
        case 'setUserRights' :
            return{
                ...state,
                userRights : action.payload.userRights
            }

        case 'setMenuData' :
            return{
                ...state,
                menuData : action.payload.menuData
            }

        default:
            return state
    }
} 

export default appReducer