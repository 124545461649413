import { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, deleteToken, isSupported } from "firebase/messaging";
import { Config, IISMethods, resBody, setReqParams } from "../config/IISMethods";

import { store } from "../index";


var vapidKey; // = envConfig.REACT_APP_FB_VAPID_KEY
var firebaseConfig;

var firebaseApp;
var messaging;



const useFirebase = () => {
    const getCurrentState = () => store.getState()

    const [permission, setPermission] = useState(null)

    useEffect(() => {
        vapidKey = getCurrentState().envConfig.FB_VAPID_KEY
        firebaseConfig = {
            apiKey: getCurrentState().envConfig.FB_API_KEY,
            authDomain: getCurrentState().envConfig.FB_AUTH_DOMAIN,
            databaseURL: getCurrentState().envConfig.FB_DATABASE_URL,
            projectId: getCurrentState().envConfig.FB_PROJECT_ID,
            storageBucket: getCurrentState().envConfig.FB_STORAGE_BUCKET,
            messagingSenderId: getCurrentState().envConfig.FB_MESSAGING_SENDER_ID,
            appId: getCurrentState().envConfig.FB_APP_ID,
            measurementId: getCurrentState().envConfig.FB_MEASUREMENT_ID,
        };
    }, [getCurrentState().envConfig])

    const firebaseConfiguration = async () => {
        firebaseApp = initializeApp(firebaseConfig);
        messaging = getMessaging(firebaseApp);
        await firebaseToken()
    }

    const firebaseToken = async () => {
        try {
            let loginInfo = IISMethods.getCookiesData("loginInfo")
            let fbtoken = await getToken(messaging, { vapidKey: vapidKey })
            if (!loginInfo['fbtoken'] || (loginInfo['fbtoken'] !== fbtoken)) {
                loginInfo['fbtoken'] = fbtoken
                IISMethods.setCookiesData("loginInfo", loginInfo)
                await addData(fbtoken)
            }
        }
        catch (error) {
            console.log("Token Error: ", error)
        }
    }

    // insert add device data for notification
    const addData = async (fbtoken) => {
        let url = Config.weburl + 'devicetoken/add'
        const useraction = 'adddevicedata'

        let reqData = {}
        reqData['appversion'] = process.env.REACT_APP_VERSION
        reqData['deviceid'] = fbtoken
        reqData['os'] = IISMethods.getOSDetail()
        reqData['osversion'] = window.navigator.appVersion
        reqData['macaddress'] = fbtoken //macaddress same as device id 
        reqData['devicemodelname'] = 'Windows'
        // reqData['edate'] = IISMethods.GetDT().toISOString()

        await setReqParams(url, reqData)
        await IISMethods.addData(useraction, false)

        if (resBody.status === 200) { }
    }

    // for Firebase delete token
    const removeToken = () => {
        deleteToken(messaging)
    }

    const requestPermission = async () => {
        try {
            // let permission = await Notification.requestPermission()
            Notification.requestPermission().then(async (permission) => {
                setPermission(permission)
                if (permission === 'granted') {
                    let issupported = await isSupported()
                    console.log("issupported", issupported)
                    if (issupported) {
                        // SW.register()
                        await firebaseConfiguration()
                    }
                }
            })
                .catch((error) => {
                    console.log(error, "notification requestPermission error")
                })
        } catch (error) {
            setPermission(null)
        }
    }

    return {
        requestPermission,
        removeToken,
        permission
    };
};

export default useFirebase;
