import Loaderbackground from "../../assets/images/Loaderbackground.svg";

const LogoLoader = () => {
    return (
        <>
            <div className="d-flex justify-content-center align-items-center h-100vh" style={{ backgroundImage: `url(${Loaderbackground})`}}>
                <div>
                    <div className='round-logo'>
                        <div className='logo-center-part'>
                            <div className='clippart'></div>
                            <div className='clippartcenter'></div>
                            <div className='clippartsecond'></div>
                        </div>
                    </div>
                    <div className='logo-bottomline'></div>
                </div>
            </div>
        </>
    )
}

export default LogoLoader;
