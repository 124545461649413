import { useState, useEffect } from "react";
import { Config, IISMethods, resBody, setReqParams } from "../config/IISMethods";
import http from "../utils/http";
//the function to call the push server: https://github.com/Spyna/push-notification-demo/blob/master/front-end-react/src/utils/http.js

import {
    isPushNotificationSupported,
    askUserPermission,
    registerServiceWorker,
    createNotificationSubscription,
    getUserSubscription,
    unregisterServiceWorker
} from "./push-notification";
//import all the function created to manage the push notifications

const pushNotificationSupported = isPushNotificationSupported();
//first thing to do: check if the push notifications are supported by the browser


export default function usePushNotifications() {
    // var pushServerSubscriptionId = ""
    const [userConsent, setSuserConsent] = useState(null);
    //to manage the user consent: Notification.permission is a JavaScript native function that return the current state of the permission
    //We initialize the userConsent with that value
    const [userSubscription, setUserSubscription] = useState(null);
    //to manage the use push notification subscription
    const [pushServerSubscriptionId, setPushServerSubscriptionId] = useState(null);
    //to manage the push server subscription
    const [error, setError] = useState(null);
    //to manage errors
    const [loading, setLoading] = useState(true);
    //to manage async actions
    const [dataset, setDataset] = useState(true)

    useEffect(() => {
        if (pushNotificationSupported) {
            setLoading(true);
            setError(false);
            registerServiceWorker().then(() => {
                setLoading(false);
            });
        }
    }, []);
    //if the push notifications are supported, registers the service worker
    //this effect runs only the first render

    useEffect(() => {
        setLoading(true);
        setError(false);
        const getExixtingSubscription = async () => {
            const existingSubscription = await getUserSubscription();
            setUserSubscription(existingSubscription);
            setLoading(false);
        };
        getExixtingSubscription();

        //check  Notification support or not (ios not supported)
        // in ipad and iphone Notification not supported
        if ('Notification' in window) {
            setSuserConsent(Notification.permission);
        } else {
            setSuserConsent('unsupported');
        }
    }, []);

    const getSubscriptions = async () => {
        let existingSubscription = await getUserSubscription();
        if (existingSubscription) {
            let getJSON = existingSubscription.toJSON()
            return getJSON.keys.auth
        } else {
            return
        }
    }

    const unregisterSW = async () => {
        await unregisterServiceWorker()
    }

    //Retrieve if there is any push notification subscription for the registered service worker
    // this use effect runs only in the first render

    /**
     * define a click handler that asks the user permission,
     * it uses the setSuserConsent state, to set the consent of the user
     * If the user denies the consent, an error is created with the setError hook
     */
    const onClickAskUserPermission = async () => {
        setLoading(true);
        setError(false);
        await askUserPermission().then(consent => {
            setSuserConsent(consent);
            if (consent !== "granted") {
                setError({
                    name: "Consent denied",
                    message: "You denied the consent to receive notifications",
                    code: 0
                });
            }
            setLoading(false);
        });
    };
    //

    /**
     * define a click handler that creates a push notification subscription.
     * Once the subscription is created, it uses the setUserSubscription hook
     */
    const onClickSusbribeToPushNotification = async () => {
        setLoading(true);
        setError(false);
        await createNotificationSubscription()
            .then(async (subscrition) => {
                setUserSubscription(subscrition);
                console.log("subscrition ==>", subscrition)
                let getKeys = subscrition.toJSON()
                await onClickSendSubscriptionToPushServer(subscrition, getKeys.keys.auth)
                setLoading(false);
            })
            .catch(err => {
                console.error("Couldn't create the notification subscription", err, "name:", err.name, "message:", err.message, "code:", err.code);
                setError(err);
                setLoading(false);
            });
    };

    /**
     * define a click handler that sends the push susbcribtion to the push server.
     * Once the subscription ics created on the server, it saves the id using the hook setPushServerSubscriptionId
     */
    const onClickSendSubscriptionToPushServer = async (subscrition, keys) => {
        // debugger
        setLoading(true);
        setError(false);

        const url = Config.weburl + "subscription" + "/add"
        const useraction = 'subscription'

        let reqData = { swid: keys, subscription: subscrition }

        await setReqParams(url, reqData)

        await IISMethods.addData(useraction, false)

        if (resBody.status === 200) {
            setPushServerSubscriptionId(reqData.swid)
            let loginInfo = IISMethods.getCookiesData("loginInfo")
            loginInfo.swid = keys
            IISMethods.setCookiesData("loginInfo", loginInfo)
        }

        // http
        //     .post("/subscription", userSubscription)
        //     .then(function (response) {
        //         setPushServerSubscriptionId(response.id);
        //         setLoading(false);
        //     })
        //     .catch(err => {
        //         setLoading(false);
        //         setError(err);
        //     });

    };

    /**
     * define a click handler that request the push server to send a notification, passing the id of the saved subscription
     */
    const onClickSendNotification = async () => {
        setLoading(true);
        setError(false);
        await http.get(`/subscription/${pushServerSubscriptionId}`).catch(err => {
            setLoading(false);
            setError(err);
        });
        setLoading(false);
    };

    /**
     * returns all the stuff needed by a Component
     */
    return {
        onClickAskUserPermission,
        onClickSusbribeToPushNotification,
        onClickSendSubscriptionToPushServer,
        pushServerSubscriptionId,
        onClickSendNotification,
        userConsent,
        pushNotificationSupported,
        userSubscription,
        error,
        loading,
        unregisterSW,
        getSubscriptions,
    };
}