import { Component } from 'react'
import { Config, IISMethods} from '../config/IISMethods'
import htmltags from "../assets/JSON/blacklisttags.json"
import htmllisteners from "../assets/JSON/blacklistlistener.json"

export default class JsCall extends Component {
    // constructor(props) {
    //     super(props)
    // }

    //All Right Sidebar Code Start

    handleRightSidebar() {
        document.getElementById('rightSidebar').classList.toggle('active-right-sidebar')
        document.body.classList.toggle('overflow-hidden')
        document.querySelector("[class~='full-overlay']").classList.toggle('show')
    }
    
    //All Right Sidebar Code End

    //All Filter Right Sidebar Code Start

    handleFilterRightSidebar() {
        document.getElementById('filterrightSidebar').classList.toggle('active-right-sidebar')
        document.body.classList.toggle('overflow-hidden')
        document.querySelector("[class~='full-overlay']").classList.toggle('show')
    }

    //All Filter Right Sidebar Code End

    //All GridMenu Code Start

    handleGridMenu() {
        // if(flag) {
        //     document.getElementById('tblDropdownContent').classList.remove('active')
        //     document.querySelector("[class~='grid-dropdown-overlay-bg']").classList.remove('show')
        // }
        // else {
        //     document.getElementById('tblDropdownContent').classList.add('active')
        //     document.querySelector("[class~='grid-dropdown-overlay-bg']").classList.add('show')
        // }
        document.getElementById('tblDropdownContent').classList.toggle('active')
        document.querySelector("[class~='grid-dropdown-overlay-bg']").classList.toggle('show')
    }

    //All GridMenu Code End

    handleSort(fieldObj) {
        const element = document.querySelector(`[class~=${fieldObj.tblsize}][name=${fieldObj.field}]`)
        element.classList.toggle('sorting_asc')
        element.classList.toggle('sorting_desc')
    }

    // used for tab switching logic in form
    /**
     * 
     * @param {String,Event Object} e - current clicked tab's event
     * @param {String} prevtabid - prev selected tab's id
     */
    handleRightSidebarTab(e,prevtabid){
        var tabname
        if(typeof(e)==='string') {
            tabname = e
        }
        else {
            e?.preventDefault()
            tabname = e?.target.id
        }

        var element = prevtabid?.length ? document.getElementById(prevtabid) : document.querySelector("[class='nav-link active']")
        element.classList.remove('active')

        element = document.getElementById(tabname)
        element.classList.add('active')

        element = prevtabid?.length ? document.getElementById(prevtabid+'-tab')  : document.querySelector("[class='tab-pane fade active show']")
        element.classList.remove('active')
        element.classList.remove('show')

        element = document.getElementById(tabname+'-tab')
        element.classList.add('active')
        element.classList.add('show')
    }
    // used for tab switching logic in form


    // close drop down item menu of rsuite
    /**
     * 
     * @param {Event Object} e - js event 
     */
    handleCloseDropdown(e) {
        var element = e.currentTarget

        element = element.parentNode

        // hemang jani 17/2/23
        if(element.hasAttribute("role"))
            element.setAttributeNode(document.createAttribute("hidden"))
        else{
            while(!element.hasAttribute("role")){
                element = element.parentNode
            }
            element.setAttributeNode(document.createAttribute("hidden"))
        }

        element = element.parentNode
        element.classList.remove('rs-dropdown-open')

        element = element.childNodes[0]
        element.removeAttribute('area-expanded')
    }


    // not in use 
    handleHasVal(id, value) {
        var element = document.getElementById(id)
        try {
            while(!element.classList.contains('form-control')) {
                element = element.parentNode
            }
        }
        catch{
            if(id==='form-contact') {
                element = document.getElementById(id)
                element = element?.childNodes[0]
                element = element?.childNodes[0]
            }
        }

        try {
            if(value){
                element.classList.add('has-val')
            }
            else{
                element.classList.remove('has-val')
            }
        }
        catch(e) {
            console.log(e)
        }
    }
    // not in use 
    
    /**
     * used while validation to add / remove red border on html input 
     * @param {String} id
     * @param {boolean} hasError - to add or remove validation class / red border
     * @param {String} type - field type of data (come in consideration to add danger class on table validation )
     */
    handleError(id, hasError,type) {
        var element = document.getElementById(id)
        try {
            while(!element.classList.contains('form-group')) {
                element = element.parentNode
            }
            // console.log("element",element)
        }
        catch{
            try {
                if(id==='form-contact') {
                    element = document.getElementById(id)
                    element = element.childNodes[0]
                    element = element.childNodes[0]
                }
            }
            catch (e){
                console.log(e)
            }
        }
        // console.log("hasError",hasError)
        try {
            if(hasError){
                element?.classList?.add('error')
            }
            else{
                element?.classList?.remove('error')
            }
        }
        catch(e){
            console.log(e)
        }
        try {
            if (hasError) {
            
                element?.classList?.add('error')
                if (type === 'table') {
                    try {
                        let tableEle = document.getElementById(id)
                        tableEle?.classList.add('border-danger')

                        setTimeout(() => {
                            tableEle?.classList.remove('border-danger')
                        }, 2000);
                    }
                    catch (e) {
                        console.log(e)
                    }
                }
            }
            else {
                element?.classList?.remove('error')
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    /**
     * to get object using field name from JSON 
     * @param {Array} formData - JSON Array
     * @param {String} field - field name 
     * @returns {Object} 
     */
    getobjectfromformdata(formData, field) {
        var object = {}
        formData?.map((data) => !Object.keys(object).length ? object = data.formfields.find(o => o.field===field) || {} : object = object)
        return object
    }

    //create regex from user defined HTML tags in all sentences 
    createHtmlTagRegex(tagName) {
        // Escape special characters in the tag name
        const escapedTagName = tagName.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
        // Create a regex pattern to match the opening and closing tags
        const regexPattern = new RegExp(`<${escapedTagName}[^>]*>[\\s\\S]*?<\\/${escapedTagName}>`, 'gi');
        return regexPattern;
    }

    //create regex from user defined word in all sentences 
    createRegexPattern(userDefinedWord) {
        // Escape user-defined word to handle special characters in regex
        const escapedUserDefinedWord = userDefinedWord.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
        // Create a regex pattern that matches the user-defined word surrounded by word boundaries
        const regexPattern = new RegExp('\\b' + escapedUserDefinedWord + '\\b', 'gi');
        return regexPattern;
    }

    //validate script, a, Link, .exe tag 
    validateTags(value) {
        let isHasError = false;
        if (value) {
            isHasError = htmltags.some((tags) => this.createHtmlTagRegex(tags).test(value))
            if (isHasError === false) {
                isHasError = htmllisteners.some((listener) => this.createRegexPattern(listener).test(value))
            }
        }
        return isHasError
    }


    /**
     * Validates Form 
     * @param {object} formData - formdata object of form
     * @param {array} validations - json of form
     * @param {string} key - to validate one filed at a time
     * @param {formname} formname - prefix used in id of field
     * @param {boolean} showerror - show error in form or not
     * @param {boolean} tabsinview - when view file have a tab type structure at that time we dont need to call handlerightsidebartab function
     * @param {boolean} fieldinview - when field is not in view and you have to return true if there is any error --> set false
     * @returns {boolean} haserror - true for error and false for no error
     */
    validateForm(formData, validations, key, formname, showerror = true, tabsinview = false, fieldinview = true)
    {  
        var hasError
        if(key) {
            var value = formData[key]
            validations = this.getobjectfromformdata(validations, key)
            // console.log("validations",validations)
            if(validations.type==='input-text' || validations.type==='number-input' || validations.type==='radio' || validations.type === 'password' || validations.type==='input-textarea' || validations.type==='contact-number') {
                try {
                    value = value.toString()
                    
                    hasError = !value.match(new RegExp(validations.regex))
                        || parseFloat(value) < validations.minvalue
                        || parseFloat(value) > validations.maxvalue
                        || value.length < validations.minlength
                        || value.length > validations.maxlength
                        || parseFloat(value) <= parseFloat(formData[validations.shouldgreaterthan])
                }
                catch(e) {
                    hasError = true
                }
            }
            else if (validations.type === 'dropdown') {
                try {
                    // for check dummy id
                    value = (value === Config.dummyid || !value) ? '' : value
                    hasError = !value
                    
                    hasError ||= (!value && validations.required)
                    hasError &&= (value || validations.required)
                    // console.log("hasError",hasError)
                }
                catch {
                    hasError = true
                }
            }
            else if(validations.type ==='table' || validations.type==='multipleselectdropdown' || validations.type==='daterangepicker' || validations.type==='timerangepicker' || validations.type==='checkpicker') {
                try {
                    hasError = !value.length
                    hasError ||= (!value.length && validations.required)
                    hasError &&= (value.length || validations.required)
                }
                catch(e) {
                    console.log(e)
                    hasError = validations.required
                }
            }
            else if(validations.type === 'html-editor'){
                // remove new lines 
                value =  value?.replaceAll('<p><br></p>','')
                // remove spaces
                value = value?.replaceAll(/<p>\s*<\/p>/g, '')
                hasError = !value?.length 
            }
            else {
                hasError = false
            }
            hasError ||= (!value && validations.required)
            hasError &&= (value || validations.required)


            if (document.getElementById((formname ? formname : 'form') + '-' + key) || !fieldinview) {
                // if(validations.type!=='dropdown' && validations.type!=='multipleselectdropdown')
                //     this.handleHasVal((formname ? formname : 'form')+'-'+key,value)
                if(showerror)
                    this.handleError((formname ? formname : 'form') + '-' + key, hasError, validations.type)
            }
            else {
                hasError = false
                
            }

            if(hasError){
                // console.log(validations)
            }
            return hasError
        }
        else {
            // console.log(formData)
            // console.log(validations)
            var hasError = false

            var view
            validations.map((data) =>
                data.formfields.filter(formfield => !formfield.istablefield).map((fields) => {
                    const tempHasError = this.validateForm(formData, validations, fields.field, formname,showerror)
                    hasError ||= tempHasError
                    if(tempHasError && !view)
                        view = data.tabname
                })
            )

            if(view && !tabsinview) {
                this.handleRightSidebarTab(view)
            }
            return hasError
        }
    }
}