export default class UserRights { 
    constructor(){
        // this._id,
        // this.menuname
        // this.formname
        // this.alias
        // this.containright
        // this.allviewright
        // this.selfviewright
        // this.alladdright,
        // this.selfaddright,
        // this.alleditright
        // this.selfeditright
        // this.alldelright
        // this.selfdelright
        // this.allprintright,
        // this.selfprintright
        // this.requestright
        // this.changepriceright
        // this.branchid
        // this.companyid
        // this.personid
        // this.userroleid
    }    

    setID(id){
        this._id = id
    }
    setMenuname(menuname){
        this.menuname = menuname
    }
    setFormname(formname){
        this.formname = formname
    }
    setAlias(alias){
        this.alias = alias
    }
    setContainright(containright){
        this.containright = containright
    }
    setAllviewright(allviewright){
        this.allviewright = allviewright
    }
    setSelfviewright(selfviewright){
        this.selfviewright = selfviewright
    }
    setAlladdright(alladdright){
        this.alladdright = alladdright
    }
    setSelfaddright(selfaddright){
        this.selfaddright = selfaddright
    }
    setAlleditright(alleditright){
        this.alleditright = alleditright
    }
    setSelfeditright(selfeditright){
        this.selfeditright = selfeditright
    }
    setAlldelright(alldelright){
        this.alldelright = alldelright
    }
    setSelfdelright(selfdelright){
        this.selfdelright = selfdelright
    }
    setAllprintright(allprintright){
        this.allprintright = allprintright
    }
    setSelfprintright(selfprintright){
        this.selfprintright = selfprintright
    }
    setRequestright(requestright){
        this.requestright = requestright
    }
    setChangepriceright(changepriceright){
        this.changepriceright = changepriceright
    }

    setAllfinancialdata(allfinancialdata){
        this.allfinancialdata = allfinancialdata
    }
    setSelffinancialdata(selffinancialdata){
        this.selffinancialdata = selffinancialdata
    }

    setCompanyid(companyid){
        this.companyid = companyid
    }
    setBranchid(branchid){
        this.branchid = branchid
    }
    setUserroleid(userroleid){
        this.userroleid = userroleid
    }
    setPersonid(personid){
        this.personid = personid
    }

    getID(){
        return this._id
    }
    getMenuname(){
        return this.menuname
    }
    getFormname(){
        return this.formname
    }
    getAlias(){
        return this.alias
    }
    getContainright(){
        return this.containright
    }
    getAllviewright(){
        return this.allviewright 
    }
    getSelfviewright(){
        return this.selfviewright
    }
    getAlladdright(){
        return this.alladdright
    }
    getSelfaddright(){
        return this.selfaddright
    }
    getAlleditright(){
        return this.alleditright
    }
    getSelfeditright(){
        return this.selfeditright
    }
    getAlldelright(){
        return this.alldelright
    }
    getSelfdelright(){
        return this.selfdelright
    }
    getAllprintright(){
        return this.allprintright
    }
    getSelfprintright(){
        return this.selfprintright
    }
    getRequestright(){
        return this.requestright
    }
    getChangepriceright(){
        return this.changepriceright
    }
    getAllfinancialdata(){
        return this.allfinancialdata 
    }
    getSelffinancialdata(){
        return this.selffinancialdata
    }
    getCompanyid(){
        return this.companyid
    }
    getBranchid(){
        return this.branchid
    }
    getUserroleid(){
        return this.userroleid
    }
    getPersonid(){
        return this.personid
    }
}