// const host = process.env.NODE_ENV === "production" ? "https://push-notification-demo-server.herokuapp.com" : "http://localhost:3000";
const host = "https://push-notification-demo-server.herokuapp.com";

async function post(path, body) {
    debugger
    const response = await fetch(`${host}${path}`, {
        credentials: "omit",
        headers: { "content-type": "application/json;charset=UTF-8", "sec-fetch-mode": "cors" },
        body: JSON.stringify(body),
        method: "POST",
        mode: "cors"
    });
    const data = await response.json();
    return data;
}

async function get(path) {
    debugger
    const response = await fetch(`${host}${path}`, {
        credentials: "omit",
        headers: { "content-type": "application/json;charset=UTF-8", "sec-fetch-mode": "cors" },
        method: "GET",
        mode: "cors"
    });
    const data = await response.json();
    return data;
}

const http = {
    post: (path, body) => post(path, body),
    get: (path) => get(path)
};

export default http;