import React from 'react'
import ReactDOM from 'react-dom/client'
import './Css'
import Head from './Head'
import RouterHead from './RouterHead'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import reducer from './redux/reducer'
import Home from './Home'
import { Config } from './config/IISMethods'
import { BrowserRouter, Switch } from 'react-router-dom'
// import '../src/assets/main-css/default-config-style.css'
// import '../src/assets/main-css/style.scss'
// import './assets/css/printdocumentstyle.css'


// export const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
export const store = createStore(reducer , Config.getServermode() == 'dev' || Config.getServermode() == 'uat' ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() : f => f)

if ( Config.getServermode() == 'prod'){
  console.log = () => {}
}

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <Provider store={store}>
    {/* <RouterHead /> */}
    <BrowserRouter>
      <Head />
      <Home />
    </BrowserRouter>
  </Provider>
)