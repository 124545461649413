import React from 'react'

function Spinner(props) {
  return (
    <>
        <div className={`${props.isbadgespinner ? 'badge' : 'd-flex justify-content-center' } ${props.className ? props.className  : ''}`}>
            <span className={`loaderApplication ${props.isbadgespinner ? 'w-14p h-14p' : ''} ${props.spinnerClassName ? props.spinnerClassName : ''}`}>
            </span>
            <span className={`ml-1 ${props.textClassName ? props.textClassName : ''}`}>{props.content || ''}
            </span>
        </div>
    </>
  )
}

export default Spinner

Spinner.defaultProps =  {
  isbadgespinner : false ,
}