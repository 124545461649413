import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import favicon from "./assets/images/favicon.ico"

export default class Head extends Component {
  render() {
    return(
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <meta name="keywords" content="Biz Compass" />
        <meta name="author" content="Biz Compass" />
        <meta name="robots" content="Biz Compass" />
        <meta name="description" content="Biz Compass" />
        <meta property="og:title" content="" />
        <meta property="og:description" content="Biz Compass" />

        <title>Biz Compass</title>

        <link rel="icon" type="image/x-icon" href={favicon} />

        <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap" rel="stylesheet"></link>

        {/* <link href="https://fonts.googleapis.com/css?family=Nunito:300,400,400i,600,700,800,900" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet" /> */}
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css"></link>
        

        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" ></link>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons+Round"></link>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons+Outlined"></link>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons+Sharp"></link>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons+Two%20Tone"></link>

        {/* <script src="./assets/js/plugins/jquery-3.3.1.min.js"></script> 
        <script src="./assets/js/jquery-ui.js"></script> npm i react-jqueryui
        <script src="./assets/js/plugins/bootstrap.bundle.min.js"></script>
        <script src="./assets/js/plugins/perfect-scrollbar.min.js"></script>
        <script src="./assets/js/scripts/script.min.js"></script>
        <script src="./assets/js/scripts/sidebar.compact.script.min.js"></script>
        <script src="./assets/js/scripts/customizer.script.min.js"></script>
        <script src="./assets/bootstrap-select/bootstrap-select.min.js"></script> npm i react-bootstrap-select
        <script src="./assets/js/moment.min.js"></script> npm i react-moment
        <script src="./assets/plugin/scrolling-tabs/jquery.scrolling-tabs.js"></script>
        <script src="./assets/js/datepicker.js"></script> import DatePicker from "react-datepicker";
        <script src="./assets/js/datepicker.en.js"></script>
        <script src="./assets/js/jquery-clockpicker.min.js"></script> import ClockPicker from 'react-clockpicker'
        <script src="./assets/js/highlight.min.js"></script> import Highlight from 'react-highlight'
        <script src="./assets/js/autocomplete.js"></script>
        <script src="./assets/js/plugins/tagging.min.js"></script> import { WithContext as ReactTags } from 'react-tag-input';
        <script src="./assets/js/scripts/tagging.script.min.js"></script>
        <script src="./assets/js/owl.carousel.js"></script> import OwlCarousel from 'react-owl-carousel';
        <script src="./assets/js/ajax/libs/highlight.js/9.12.0/highlight.min.js"></script>
        <script src="./assets/js/plugins/quill.min.js"></script> import ReactQuill from 'react-quill';
        <script src="./assets/js/plugins/toastr.min.js"></script> npm i react-toastr; npm i react-redux-toastr
        <script src="./assets/datetimepicker/spectrum.min.js"></script> import Spectrum from "react-spectrum"; import {Provider, defaultTheme, Button} from '@adobe/react-spectrum';
        <script src="./assets/js/jquery.marquee.js"></script> import Marquee from "react-fast-marquee"; */}
      </Helmet>
    )
  }
}
