import React, { Component } from 'react'
import { BrowserRouter, Route, Routes, Switch } from 'react-router-dom'
// import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'
import Home from './Home'
import SignIn from './Controller/SignIn'
import { IISMethods } from './config/IISMethods'
import { ToastContainer } from 'react-toastify'
// import Configure from './InitialInfo/Configure'
import { connect } from 'react-redux'
import { clearData, setProps } from './redux/actions'
import { Router } from 'react-router-dom'
// import OnlineApptitudeTest from './view/Recruitment Master/OnlineApptitudeTest'
// import { BrowserRouter, Routes, Route } from "react-router-dom";


class RouterHead extends Component {

  constructor(props) {
    super(props)

    this.getStatus = this.getStatus.bind(this)
  }

  getStatus() {
    try {
      if (!this.props.loginresp.data.data[0].status) {
        return true
      }
    }
    catch {
      return false
    }
  }

  render() {
    const pagename = IISMethods.getpagename()
    return (
      <>
        <Router>
          <Routes>
            {
              pagename === "" || pagename === "login" ?
                <Route path="/" element={<SignIn />} />
              : 
                <Route path="/" element={<Home />} />
            }
          </Routes>
        </Router>
        {/* <BrowserRouter>
          <Routes>
            {
              pagename === "" || pagename === "login" ?
                <Route path="/" element={<SignIn />} />
                :
                  <Route path="/home" element={<Home />} />
            }
          </Routes>
        </BrowserRouter> */}

        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          // closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
          
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  clearData: (payload) => dispatch(clearData(payload)),
  setProps: (payload) => dispatch(setProps(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(RouterHead)