import React, { useEffect, useState } from 'react'
import ModalRsuite from './ModalRsuite'
import { Config, IISMethods, JsCall } from '../../config/IISMethods'
import Spinner from './Spinner';

let formfields = [{
    'field': 'recoveryCode',
    'text': 'Recovery Code',
    'type': Config.getHtmlcontorls()['input-text'],
    'disabled': false,
    'required': true,
    'defaultvisibility': true,
    'gridsize': Config.getGridclasses()['col-12'],
}]

const DualAuthRecovery = (props) => {

    const [recoveryCode, setRecoveryCode] = useState('')

    useEffect(() => {
        return () => setRecoveryCode('')
    }, [])

    const handleSubmitRecoveryCode = async () => {
        if (JsCall.validateForm({ recoveryCode: recoveryCode }, [{ formfields }])) {
            IISMethods.notify("Fill all required data", 2)
            props.setRecoveryButtonDisable(false)
        }
        else {
            await props.verifyOTP(recoveryCode)
            setRecoveryCode('')
        }
    }

    return (
        <>
            <ModalRsuite
                size={'xs'}
                open={props.open}
                onClose={props.onClose}
                title={<span className="formnamelbl" id="myprofiledetailsTitle">{!props.title ? 'Two-Factor Authentication' : props.title}</span>}
                body={
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <div className="right-sidebar-content">
                                    <div className="row">
                                        <div className={formfields[0].gridsize}>
                                            <div className="form-group validate-input">
                                                <label className="label-form-control">{formfields[0].text} <span className="text-danger"> * </span></label>
                                                <input
                                                    className="form-control"
                                                    placeholder={`Enter ${formfields[0].text}`}
                                                    onKeyUp={(e) => e.key === 'Enter' && !props.recoveryButtonDisable && handleSubmitRecoveryCode()}
                                                    onChange={(e) => setRecoveryCode(e.target.value)}
                                                    value={recoveryCode}
                                                    id={'form-' + formfields[0].field}
                                                    onKeyPress={(e) => {
                                                        // only numbers
                                                        if (!(e.which >= 48 && e.which <= 57)) {
                                                            e.preventDefault()
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div className='d-flex mt-4'>
                                                <button className='btn btn-primary-dark ml-auto' disabled={props.recoveryButtonDisable} onClick={() => {
                                                    props.setRecoveryButtonDisable(true)
                                                    !props.recoveryButtonDisable && handleSubmitRecoveryCode()
                                                }}>
                                                    {
                                                        props.recoveryButtonDisable ?
                                                            <Spinner content={'Verify'} /> :
                                                            'Verify'
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />
        </>
    )
}

export default DualAuthRecovery