import { IISMethods } from '../config/IISMethods'
const pushServerPublicKey = "BOO_HB50LHlZt_5Axn6x7LJm5G6pDWnWxkMbpXknFxcSdKYwA4qmGIgJzG_i8WszAJU0HvvVX7zxg-XI6pAVEEo";



function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
/**
 * checks if Push notification and service workers are supported by your browser
 */
function isPushNotificationSupported() {
  return "serviceWorker" in navigator && "PushManager" in window;
}

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
async function askUserPermission() {
  return await Notification.requestPermission();
}
/**
 * shows a notification
 */
function sendNotification() {
  const img = "/images/jason-leung-HM6TMmevbZQ-unsplash.jpg";
  const text = "Take a look at this brand new t-shirt!";
  const title = "New Product Available";
  const options = {
    body: text,
    icon: "/images/jason-leung-HM6TMmevbZQ-unsplash.jpg",
    vibrate: [200, 100, 200],
    tag: "new-product",
    image: img,
    badge: "https://spyna.it/icons/android-icon-192x192.png",
    actions: [{ action: "Detail", title: "View", icon: "https://via.placeholder.com/128/ff0000" }]
  };
  navigator.serviceWorker.ready.then(function (serviceWorker) {
    serviceWorker.showNotification(title,
      {
        body: "Push notification", //the body of the push notification
        // image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/97/OOjs_UI_icon_bell.svg/1024px-OOjs_UI_icon_bell.svg.png",
        icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/97/OOjs_UI_icon_bell.svg/1024px-OOjs_UI_icon_bell.svg.png", // icon 
      })
  });
}

/**
 *
 */
function registerServiceWorker() {
  
  window.self.addEventListener("install", function(event) {
    event.waitUntil(preLoad());
  });
  
  var preLoad = function(){
    
    return caches.open("offline").then(function(cache) {
      
      return cache.addAll(["../../index.js"]);
    });
  };
  
  window.self.addEventListener("fetch", function(event) {
    // 
    event.respondWith(checkResponse(event.request).catch(function() {
      return returnFromCache(event.request);
    }));
    event.waitUntil(addToCache(event.request));
  });

  // Juhil - 29-12-20222
  window.self.addEventListener("notificationclick", function(event) {
    let url = 'https://example.com/some-path/'
    // 
    // 
    // event.waitUntil(
    //     clients.matchAll({type: 'window'}).then( windowClients => {
    //         // Check if there is already a window/tab open with the target URL
    //         for (var i = 0; i < windowClients.length; i++) {
    //             var client = windowClients[i];
    //             // If so, just focus it.
    //             if (client.url === url && 'focus' in client) {
    //                 return client.focus();
    //             }
    //         }
    //         // If not, then open the target URL in a new window/tab.
    //         if (clients.openWindow) {
    //             return clients.openWindow(url);
    //         }
    //     })
    // );
  })
  // end
  
  var checkResponse = function(request){
    return new Promise(function(fulfill, reject) {
      fetch(request).then(function(response){
        if(response.status !== 404) {
          fulfill(response);
        } else {
          reject();
        }
      }, reject);
    });
  };
  
  var addToCache = function(request){
    return caches.open("offline").then(function (cache) {
      return fetch(request).then(function (response) {
        return cache.put(request, response);
      });
    });
  };
  
  var returnFromCache = function(request){
    return caches.open("offline").then(function (cache) {
      return cache.match(request).then(function (matching) {
       if(!matching || matching.status == 404) {
         return cache.match("../../index.js");
       } else {
         return matching;
       }
      });
    });
  };

  return navigator.serviceWorker.register("/serviceWorker.js", { scope: "/" });
}

async function unregisterServiceWorker() {
  return navigator.serviceWorker.ready
    .then(function (serviceWorker) {
      return serviceWorker.pushManager.getSubscription();
    })
    .then(function (pushSubscription) {
      if (pushSubscription) {
        return pushSubscription.unsubscribe();
      }
      return
    });
  // const serviceWorker = await navigator.serviceWorker.ready;
  // const subscription = await serviceWorker.pushManager.getSubscription();
  // return subscription.unsubscribe()
}

/**
 *
 * using the registered service worker creates a push notification subscription and returns it
 *
 */
async function createNotificationSubscription() {
  //wait for service worker installation to be ready

  const serviceWorker = await navigator.serviceWorker.ready;

  // subscribe and return the subscription  
  return await serviceWorker.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(pushServerPublicKey),
  });
  // key: serviceWorker
}

/**
 * returns the subscription if present or nothing
 */
async function getUserSubscription() {
  //wait for service worker installation to be ready, and then
  return navigator.serviceWorker?.ready
    .then(function (serviceWorker) {
      return serviceWorker.pushManager.getSubscription();
    })
    .then(function (pushSubscription) {
      return pushSubscription;
    });
}

export {
  isPushNotificationSupported,
  askUserPermission,
  registerServiceWorker,
  sendNotification,
  createNotificationSubscription,
  getUserSubscription,
  unregisterServiceWorker
};