// Action Creators
export const clearData = () => {
  return {
    type: 'clearData'
  }
}

// export const clearTimerData = () => {
//   return {
//     type: 'clearTimerData'
//   }
// }

export const setProps = (payload) => {
  return {
    type: 'setProps',
    payload
  }
}

export const updateData = (payload) => {
  return {
    type: 'updateData',
    payload
  }
}

// export const clearApplicationData = () => {
//   return {
//     type: 'clearApplicationData'
//   }
// }

export const clearMasterDataList = () => {
  return {
    type: 'clearMasterDataList'
  }
}

export const clearsearchResultArray = () => {
  return {
    type: 'clearsearchResultArray'
  }
}

export const updatesearchResultArray = (payload) => {
  return {
    type: 'updatesearchResultArray',
    payload
  }
}



// export const setApplicationData = (payload) => {
//   return {
//     type: 'setApplicationData',
//     payload
//   }
// }

// export const setListOfCandidates = (payload) => {
//   return {
//     type: 'setListOfCandidates',
//     payload
//   }
// }

 // used in announcement controller
 export const setGridListData = (payload) => {
  return {
    type: 'setGridListData',
    payload
  }
}

export const setCookiesData = (payload) => {
  return {
    type: 'setCookiesData',
    payload
  }
}

export const setLocalStorageData = (payload) => {
  return {
    type: 'setLocalStorageData',
    payload
  }
}

export const setUserRights = (payload) => {
  return {
    type: 'setUserRights',
    payload
  }
}

export const setMenuData = (payload) => {
  return {
    type: 'setMenuData',
    payload
  }
}

