import React, { useEffect, useState, Suspense } from 'react'
// import Header from './Header'
// import Sidebar from './Sidebar'
// import Footer from './Footer'
// import SidebarRouter from './SidebarRouter'
import Marquee from "react-fast-marquee"
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { connect } from 'react-redux'
import { setProps, clearData, setUserRights, setMenuData } from './redux/actions'
import { IISMethods, JsCall, setReqParams, pagelimit, resBody, Config, socket } from './config/IISMethods'
import { Offline, Online } from 'react-detect-offline'
import usePushNotifications from './swnotification/usePushNotifications'
import { store } from './index'
// import SignIn from './Controller/SignIn'
import { ToastContainer } from 'react-toastify'
import LogoLoader from './view/Components/LogoLoader'
import _UserRight from './config/UserRight'
import useFirebase from "./swnotification//useFirebase"


const SignIn = React.lazy(() => lazyRetry(() => import('./Controller/SignIn')))
const Header = React.lazy(() => lazyRetry(() => import('./Header')))
const Sidebar = React.lazy(() => lazyRetry(() => import('./Sidebar')))
const Footer = React.lazy(() => lazyRetry(() => import('./Footer')))
const SidebarRouter = React.lazy(() => lazyRetry(() => import('./SidebarRouter')))

function lazyRetry(componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      // window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
      Object.keys(IISMethods.getSessionData('retry-lazy-refreshed') || 'false').length ? IISMethods.getSessionData('retry-lazy-refreshed') : 'false'
    )
    // try to import the component
    componentImport().then((component) => {
      // window.sessionStorage.setItem('retry-lazy-refreshed', 'false') // success so reset the refresh
      IISMethods.setSessionData('retry-lazy-refreshed', 'false') // success so reset the refresh
      resolve(component)
    }).catch((error) => {
      if (!hasRefreshed) { // not been refreshed yet
        // window.sessionStorage.setItem('retry-lazy-refreshed', 'true') // we are now going to refresh
        IISMethods.setSessionData('retry-lazy-refreshed', 'true') // we are now going to refresh
        return window.location.reload() // refresh the page
      }
      reject(error) // Default error behaviour as already tried refresh
    })
  })
}


const Home = (props) => {
  const { userConsent, getSubscriptions, onClickAskUserPermission, onClickSusbribeToPushNotification, unregisterSW } = usePushNotifications()
  const { requestPermission } = useFirebase()
  const [state, setState] = useState({
    isClose: true,
    miniscreenIsClose: true,
    isMiniScreen: false,
    render: false,
    windowWidth: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  })

  const pagename = IISMethods.getpagename()

  const [showAnimation, setShowAnimation] = useState(true)
  const [isSearch, setIsSearch] = useState(false)


  const isConsentGranted = userConsent === "granted"

  const getCurrentState = () => {
    return store.getState()
  }

  const loginDetails = IISMethods.getCookiesData('loginInfo')

  //adding component life cycle
  useEffect(async () => {
    useeffectRender()
    setShowAnimation(true)

    if (loginDetails) {
      await getUserRIghtsAPI()
    }

    setTimeout(() => {
      setShowAnimation(false)
    }, 300)
  }, [])



  const useeffectRender = () => {
    updateDimensions()
    window.addEventListener("resize", updateDimensions);

    if ([true, false].includes(IISMethods.getLocalData("isClose"))) {
      setState({
        ...state,
        isClose: IISMethods.getLocalData("isClose")
      })
    } else {
      IISMethods.setLocalData("isClose", true)
    }

    // let loginInfo = IISMethods.getCookiesData("loginInfo")

    // console.log("setuid")

    // if (loginInfo) {
    //   // var socketIO = io.connect(Config.getSocketserver())
    //   socket.emit("setuid", loginInfo.uid)
    //   console.log(socket)
    //   socket.emit("subscribe", loginInfo.uid)
    // }

    // if (!IISMethods.getCookiesData('loginInfo').projectid) {
    //   setTimeout(async () => await getProjectsList(1), 1)
    // }

    handleTDTagClasses()
  }

  // useEffect(() => {
  //   //? for Web Notification Permission
  //   (async function () {
  //     let loginInfo = IISMethods.getCookiesData("loginInfo")
  //     // let sessionData = {}
  //     // sessionData.projectid = 

  //     if (!loginInfo) {
  //       if (props.isConsentGranted) {
  //         await deleteSubscription()
  //       }
  //       // await logOut()
  //     } else {
  //       if (!isConsentGranted) {
  //         let loginInfo = IISMethods.getCookiesData("loginInfo")
  //         delete loginInfo.swid
  //         IISMethods.setCookiesData("loginInfo", loginInfo)
  //         // IISMethods.setSessionData("sessionData" , )
  //         await onClickAskUserPermission()
  //       } else {
  //         let swid = IISMethods.getCookiesData("loginInfo").swid
  //         if (!swid) {
  //           await onClickSusbribeToPushNotification()
  //         }
  //       }
  //     }
  //   })()
  //   // return () => { }
  // }, [isConsentGranted])


  const logOut = async () => {
    IISMethods.logout()
  }
  // Call User Rights Api Start
  const getUserRIghtsAPI = async () => {
    const url = Config.weburl + 'logindata'
    const useraction = 'listlogindata'
    const reqData = {
      personid: IISMethods.getCookiesData("loginInfo").uid
    }

    await setReqParams(url, reqData)
    await IISMethods.listData(useraction, true, {}, 'logindata')

    if (resBody.status === 200) {
      var userrights = new Array()
      resBody.userrights?.map((obj) => {
        var UserRight = new _UserRight()

        UserRight.setID(obj._id)
        UserRight.setMenuname(obj.menuname)
        // UserRight.setFormname(obj.formname)
        UserRight.setAlias(obj.alias)
        // UserRight.setContainright(obj.containright)
        UserRight.setAllviewright(obj.allviewright)
        UserRight.setSelfviewright(obj.selfviewright)
        UserRight.setAlladdright(obj.alladdright)
        UserRight.setSelfaddright(obj.selfaddright)
        UserRight.setAlleditright(obj.alleditright)
        UserRight.setSelfeditright(obj.selfeditright)
        UserRight.setAlldelright(obj.alldelright)
        UserRight.setSelfdelright(obj.selfdelright)
        UserRight.setAllprintright(obj.allprintright)
        UserRight.setSelfprintright(obj.selfprintright)
        UserRight.setRequestright(obj.requestright)
        UserRight.setChangepriceright(obj.changepriceright)
        //01-02-23
        UserRight.setAllfinancialdata(obj.allfinancialdata)
        UserRight.setSelffinancialdata(obj.selffinancialdata)

        userrights.push(UserRight)
      })

      // set user rights in redux
      await props.setUserRights({ userRights: IISMethods.getCopy(userrights) })
      // set menuData for side bar
      await props.setMenuData({ menuData: IISMethods.getCopy(resBody.menudata) })
      // set headerAnnouncement & usertypeid
      await props.setProps({
        headerAnnouncement: resBody.announcement,
        userType: { usertypeid: resBody.data[0].usertypeid },
        envConfig: IISMethods.getCopy(resBody.firebaseconfig)
      })

      //  get permission for Service worker notification 
      await requestPermission()

    }
  }

  const deleteSubscription = async () => {

    let url = Config.weburl + "subscription" + "/delete"
    let useraction = 'subscription'

    let swid = await getSubscriptions()

    let reqData = { swid }

    await setReqParams(url, reqData)

    await IISMethods.deleteData(useraction, false)

    if (resBody.status === 200) {
      await unregisterSW()
      // debugger
    }
  }

  const updateDimensions = () => {
    setState({
      ...state,
      windowWidth:
        document.documentElement.clientWidth ||
        document.body.clientWidth,
      isMiniScreen: state.windowWidth < 768 ? true : false
    })
  }


  const handleOverlayClick = async () => {
    let rightSidebar = document.querySelectorAll("[class~='right-sidebar']")
    for (let i = 0; i < rightSidebar.length; i++) {
      rightSidebar[i].classList.remove('active-right-sidebar')
    }
    document.body.classList.remove('overflow-hidden')
    document.querySelector("[class~='full-overlay']").classList.remove('show')

    // clear global notification data
    getCurrentState().notifications = []
    getCurrentState().notificationLimit = 20
    getCurrentState().notificationPageNo = 1
    await props.setProps({ notifications: getCurrentState().notifications, notificationLimit: getCurrentState().notificationLimit, notificationPageNo: getCurrentState().notificationPageNo })
  }

  const handleTDTagClasses = () => {
    const elements = document.querySelectorAll("td")
    for (const element of elements) {
      if (!element.childNodes.length && element.className === '') {
        element.classList.add('td-direct-data')
      }
    }
  }

  //not in use
  // const refreshPage = () => {
  //   window.location.reload();
  // }

  //not in use
  // const setUserRights = async () => {
  //   const url = Config.weburl + 'getuserrights'
  //   const useraction = 'list' + 'getuserrights' + 'data'

  //   var filter = {
  //     "userroleid": IISMethods.getCookiesData('loginInfo').userroleid,
  //     "companyid": IISMethods.getCookiesData('loginInfo').companyid,
  //     "branchid": IISMethods.getCookiesData('loginInfo').branchid
  //   }

  //   // //apply filter before making request
  //   // Object.entries(getCurrentState().filterData).forEach(([key, value]) => {
  //   //   if (typeof (value) === 'number') {
  //   //     if (value !== 0)
  //   //       filter[key] = value
  //   //   }
  //   //   else
  //   //     filter[key] = value
  //   // })

  //   if (!getCurrentState().data.length) {
  //     await props.setProps({ pageNo: 1 })
  //   }

  //   const reqBody = {
  //     paginationinfo: {
  //       pageno: getCurrentState().pageNo,
  //       pagelimit: getCurrentState().pagelimit,
  //       filter: filter,
  //       sort: getCurrentState().sortData
  //     }
  //   }

  //   await setReqParams(url, reqBody)

  //   await IISMethods.listData(useraction)

  //   if (resBody.status === 200) {
  //     localStorage.setItem('menuData', JSON.stringify(resBody.menudata))
  //     localStorage.setItem('userRights', JSON.stringify(resBody.userrights))
  //     refreshPage()
  //   }
  //   else {
  //   }

  // }

  // not in use
  // const handleHeaderUserChange = async (key, value) => {
  //   var loginInfo = IISMethods.getCookiesData("loginInfo")
  //   if (loginInfo[key] !== value) {
  //     loginInfo[key] = value
  //     IISMethods.setCookiesData("loginInfo", loginInfo)
  //     const rightSidebarFormData = IISMethods.getCopy(props.rightSidebarFormData)
  //     console.log(rightSidebarFormData)
  //     await props.setProps({ rightSidebarFormData: [] })
  //     await props.setProps({ rightSidebarFormData: rightSidebarFormData })
  //     await setUserRights()
  //   }
  // }

  const miniscreencloseSidebar = () => {
    setState(
      {
        ...state,
        miniscreenIsClose: !state.miniscreenIsClose,
      }
    )

  }

  const closeSidebar = (close = false) => {
    if (close) {
      IISMethods.setLocalData("isClose", true)
      setState(
        {
          ...state,
          isClose: true,
        }
      )
    }
    else {
      IISMethods.setLocalData("isClose", !state.isClose)
      setState(
        {
          ...state,
          isClose: !state.isClose,
        }
      )

    }
  }

  const handlePageChange = async (alias, flag) => {
    if (pagename !== alias && !window.event.ctrlKey) {
      await props.clearData()
      await props.setProps({
        fieldOrder: [],
        newFieldOrder: [],
        nextpage: 0,
        pageNo: 1,
        pagelimit: 20,
        pageName: '',
        formName: '',
        sortData: {},
        formData: {},
        filterData: {},
        oldFilterData: {},
        modal: {},
        masterData: {},
        masterDataList: {}
      })
    }

    setState({ ...state })

    if (flag) {
      const newPageName = "masters";
      const newUrl = window.location.href.replace(/\/[^\/]*$/, `/${newPageName}`);
      window.history.pushState({}, "", newUrl);
    }
  }

  // const baseClass = state.isMiniScreen === true ? "app-admin-wrap layout-sidebar-compact sidebar-dark clearfix sidenav-open-sticky fa-flip" : "app-admin-wrap layout-sidebar-compact sidebar-dark clearfix"
  // const newClass = state.isMiniScreen === true ? "sidenav-open" : "sidenav-open-sticky fa-flip"

  const baseClass = state.isMiniScreen === true ? "app-admin-wrap layout-sidebar-compact sidebar-dark clearfix sidenav-open-sticky " : "app-admin-wrap layout-sidebar-compact sidebar-dark clearfix"
  const newClass = state.isMiniScreen === true ? "sidenav-open" : "sidenav-open-sticky "

  try {
    return (
      <>
        <>
          {

            showAnimation ?
              <LogoLoader />
              :
              !IISMethods.getCookiesData("loginInfo") ?
                <Routes>
                  <Route path="/*" element={<SignIn />} />
                </Routes>
                :
                // !IISMethods.getCookiesData("loginInfo") && (pagename === "" || pagename === 'login') ?
                //   <Routes>
                //     <Route path="/*" element={<SignIn />} />
                //   </Routes>
                //   :
                IISMethods.getCookiesData('loginInfo') && !IISMethods.getCookiesData('loginInfo')?.status ?
                  <>
                    <div className={state.isMiniScreen ? (state.isClose && !state.miniscreenIsClose ? `${baseClass} ` : `${baseClass} ${newClass}`) : (!state.isClose && state.miniscreenIsClose ? `${baseClass} ${newClass}` : `${baseClass}`)}>
                      {/* <div className="app-admin-wrap layout-sidebar-compact sidebar-dark clearfix"> */}
                      {/* <BrowserRouter> */}
                      {/* SIDEBAR */}
                      {/* <Sidebar closeSidebar={closeSidebar} /> */}
                      <Sidebar
                        pagename="menudesign"
                        closeSidebar={closeSidebar}
                        state={state}
                        handlePageChange={handlePageChange}
                        deleteSubscription={deleteSubscription}
                        isConsentGranted={isConsentGranted}
                      />
                      {/* SIDEBAR */}

                      <div className="main-content-wrap d-flex flex-column"
                        onClick={(e) => {
                          if (e.target.id != "filter-header-search") {
                            setIsSearch(false)
                          }
                        }}>
                        <div className="full-overlay" onClick={handleOverlayClick}></div>
                        <div className="agreement-generate-overlay" id="agreement-generate-overlay" >
                          <div className="agreement-spinner-center d-flex">
                            <div className="agreement-spinner agreement-spinner-1"></div>
                            <span className="agreement-spinner-message ml-1 mt-1" id="agreement-generate-overlay-message"></span>
                          </div>
                        </div>
                        <div className="grid-dropdown-overlay-bg" onClick={JsCall.handleGridMenu}></div>
                        <div className="transparent-overlay-bg" onClick={() => document.querySelector("[class~='transparent-overlay-bg']").classList.remove('show')}></div>
                        {/* Header Start */}
                        <Header
                          miniscreencloseSidebar={miniscreencloseSidebar}
                          handlePageChange={handlePageChange}
                          pagename={IISMethods.getpagename()}
                          deleteSubscription={deleteSubscription}
                          // handleHeaderUserChange={handleHeaderUserChange}
                          isConsentGranted={isConsentGranted}
                          setIsSearch={setIsSearch}
                          isSearch={isSearch}
                        />
                        {/* Header End */}

                        {/* Main Content Start */}
                        <Suspense fallback={<></>}>
                          <SidebarRouter
                            miniscreencloseSidebar={miniscreencloseSidebar}
                            handlePageChange={handlePageChange}
                          // render = {state.render}
                          />
                        </Suspense>
                        {/* Main Content End */}


                        {/* Footer Start */}
                        <Footer />
                        {/* Footer End */}


                      </div>
                      {/* </BrowserRouter> */}
                    </div>
                  </>
                  :
                  <>
                  </>
          }

          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={true}
            // closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            theme="light"
          />
        </>

      </>
    )
  }
  catch (e) {
    console.error(e)
  }
}

const mapStateToProps = state => ({
  // ...state
  rightSidebarFormData: state.rightSidebarFormData,
  pageNo: state.pageNo,
  notificationPageNo: state.notificationPageNo,
  notificationLimit: state.notificationLimit,
  notifications: state.notifications
})

const mapDispatchToProps = dispatch => ({
  setProps: (payload) => dispatch(setProps(payload)),
  clearData: (payload) => dispatch(clearData(payload)),
  setUserRights: (payload) => dispatch(setUserRights(payload)),
  setMenuData: (payload) => dispatch(setMenuData(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)