export default class LoginInfo { 
    constructor(){
        // this.firstname
        // this.lastname
        // this.contact
        // this.personemail
        // this.token
        // this.unqkey
        // this.uid
        // this.subdomainname
        // this.userroles
        // this.branches
        // this.userroleid
        // this.branchid
        // this.companyid
        // this.profilepic
    }

    setFirstname(firstname){
        this.firstname = firstname
    }
    setLastname(lastname){
        this.lastname = lastname
    }
    setContact(contact){
        this.contact = contact
    }
    setPersonemail(personemail){
        this.personemail = personemail
    }
    setToken(token){
        this.token = token
    }
    setUnqkey(unqkey){
        this.unqkey = unqkey
    }
    setUid(uid){
        this.uid = uid
    }
    setSubdomainName(subdomainname){
        this.subdomainname = subdomainname
    }
    setUserroles(userroles){
        this.userroles = userroles
    }
    setBranches(branches){
        this.branches = branches
    }
    setUserroleID(userroleid){
        this.userroleid = userroleid
    }
    setBranchID(branchid){
        this.branchid = branchid
    }
    setCompanyID(companyid){
        this.companyid = companyid
    }

    setCompanyCountryID(companycountryid){
        this.companycountryid = companycountryid
    }

    setCompanyStateId(companystateid){
        this.companystateid = companystateid
    }

    setCompanyCityId(companycityid){
        this.companycityid = companycityid
    }

    setCompanyName(companyname) {
        this.companyname = companyname
    }
    setProfilePic(profilepic) {
        this.profilepic = profilepic
    }
    setStatus(status) {
        this.status = status
    }
    setTheme(theme) {
        this.theme = theme
    }
    setDateFormate(dateformat){
        this.dateformat = dateformat
    }
    setTimeFormate(timeformat){
        this.timeformat = timeformat
    }
    setReferralcode(referralcode){
        this.referralcode = referralcode
    }
    setUserTypeId(usertypeid){
        this.usertypeid = usertypeid
    }
    
    getFirstname(){
        return this.firstname 
    }
    getLastname(){
        return this.lastname 
    }
    getContact(){
        return this.contact
    }
    getPersonemail(){
        return this.personemail
    }
    getToken(){
        return this.token
    }
    getUnqkey(){
        return this.unqkey
    }
    getUid(){
        return this.uid
    }
    getSubdomainName(){
        return this.subdomainname
    }
    getUserroles(){
        return this.userroles
    }
    getBranches(){
        return this.branches
    }
    getUserroleID(){
        return this.userroleid
    }
    getBranchID(){
        return this.branchid
    }
    getCompanyID(){
        return this.companyid
    }
    getCompanyName() {
        return this.companyname
    }
    getProfilePic() {
        return this.profilepic 
    }
    getStatus() {
        return this.status 
    }
    getTheme() {
        return this.theme
    }
    getDateFormate(dateformat){
        return this.dateformat
    }
    getTimeFormate(timeformat){
        return this.timeformat 
    }
    getReferralcode(referralcode){
        return this.referralcode 
    }
    getUserTypeId(){
       return this.usertypeid 
    }
}